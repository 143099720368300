<template>
  <el-dialog
    :visible.sync="dialogVisible"
    center
    width="30%"
    :close-on-click-modal="false"
    :title="isEdit ? '编辑充电桩' : '添加充电桩'"
    @close="cancel"
  >
    <el-form
      size="small"
      label-width="100px"
      ref="formRef"
      :model="form"
      :rules="rules"
    >
    <el-form-item label="充电桩编号" prop="pileCode">
        <el-input v-model="form.pileCode"></el-input>
      </el-form-item>
      <el-form-item label="充电桩名称" prop="pileName">
        <el-input v-model="form.pileName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        v-if="isEdit"
        class="buttons"
        size="small"
        type="primary"
        @click="confirmUpdate"
        >确 定</el-button
      >
      <el-button
        v-else
        class="buttons"
        size="small"
        type="primary"
        @click="confirmAdd"
        >确 定</el-button
      >
      <el-button class="buttons" size="small" @click="cancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    stationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: true,
      isEdit: false,
      id: "",
      form: {
        pileCode: "",
        pileName: "",
      },
      rules: {
        pileName: [
          {
            required: true,
            message: "请输入充电桩名称",
            trigger: ["blur", "change"],
          },
        ],
        pileCode: [
          {
            required: true,
            message: "请输入充电桩编号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    confirmAdd() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/asset/ChargePile/save",
            "post",
            { stationId: this.stationId, ...this.form }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    confirmUpdate() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data } = await this.$http(
            "api1",
            "/api/asset/ChargePile/update",
            "post",
            {
              id: this.id,
              ...this.form,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.$emit("confirm");
          }
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
